import React, { useEffect } from 'react'
import { Button, Col, Divider, Row, Tooltip } from 'antd'
import { getOr, isEmpty, orderBy } from 'lodash/fp'
import { connect } from 'react-redux'
import { RedoOutlined } from '@ant-design/icons'

import { listStations, refreshStations, updateStation } from '../../data/station/actions'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import { getAuthUser } from '../../data/auth/selectors'
import { getFeedback } from '../../data/feedback/selectors'
import Message from '../../ui/message'

import StationCard from './station-card'

export const FB_KEY_REFRESH_STATIONS = 'fbRefreshStations'

const SettingStations = (props) => {
  const { feedback, onInit, onRefresh, stations, updateStation, user } = props

  const isRunning = getOr(false, 'is_running')(user)

  const activeStations = orderBy('station_name', 'asc')(stations.filter((station) => station.is_active))
  const inactiveStations = orderBy('station_name', 'asc')(stations.filter((station) => !station.is_active))

  const activeList = activeStations.map((station) => (
    <StationCard key={station.pk} station={station} updateStation={updateStation} />
  ))
  const inactiveList = inactiveStations.map((station) => (
    <StationCard key={station.pk} station={station} updateStation={updateStation} />
  ))

  useEffect(() => {
    onInit()
  }, [])

  return (
    <Row>
      <Col xs={24} sm={16}>
        {isRunning
          ? (
            <Tooltip title='You cannot refresh the stations while searching.'>
              <Button disabled icon={<RedoOutlined />} style={{ float: 'right' }}>
                Refresh
              </Button>
            </Tooltip>
            )
          : (
            <Button icon={<RedoOutlined />} loading={feedback.isLoading} style={{ float: 'right' }} onClick={onRefresh}>
              Refresh
            </Button>
            )}

        <h2 style={{ marginTop: '20px' }}>Active</h2>
        {isEmpty(activeList) ? <Message title='No desired stations enabled' warning /> : activeList}

        <Divider />
        <h2>Inactive</h2>
        {inactiveList}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    stations: selectors.list(state, ENTITY_TYPE.STATIONS),
    user: getAuthUser(state),
    feedback: getFeedback(state, FB_KEY_REFRESH_STATIONS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listStations()),
    onRefresh: () => dispatch(refreshStations({ feedbackKey: FB_KEY_REFRESH_STATIONS, forceRefresh: true })),
    updateStation: (stationId, data) => dispatch(updateStation(stationId, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingStations)
