import React, { useEffect, useState } from 'react'
import getOr from 'lodash/fp/getOr'
import { Card, Col, InputNumber, Row, Switch, Radio } from 'antd'
import { debounce } from 'lodash/fp'
import styled from 'styled-components'

import { COLORS } from '../../constants/styles'

const StyledCard = styled(Card)`
  box-shadow: 0 0 1px 1px ${COLORS.ORANGE_FLEX};
  margin: 15px 0;
  .ant-card-head {
    background-color: ${COLORS.ORANGE_LIGHT};
  }
  .ant-card-body:empty {
    display: none;
  }
`

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`

const STATION_FIELDS = {
  MIN_RATE: 'min_rate',
  TOTAL_PAY: 'total_pay',
  IS_USING_MIN_RATE: 'is_using_min_rate',
  ARRIVAL_TIME: 'arrival_time',
}

const StationCard = ({ station, updateStation }) => {
  const [selectedOption, setSelectedOption] = useState(STATION_FIELDS.MIN_RATE)

  useEffect(() => {
    const isUsingMinRate = getOr(true, 'is_using_min_rate')(station)
    if (isUsingMinRate) {
      setSelectedOption(STATION_FIELDS.MIN_RATE)
    }
    else {
      setSelectedOption(STATION_FIELDS.TOTAL_PAY)
    }
  }, [station])

  const handleOptionChange = (value) => {
    handleUpdateStation(STATION_FIELDS.IS_USING_MIN_RATE, value === STATION_FIELDS.MIN_RATE)
    setSelectedOption(value)
  }

  const handleUpdateStation = debounce(500)((key, value) => {
    let data = {
      [key]: value
    }

    if (key === STATION_FIELDS.MIN_RATE) {
      data = {
        ...data,
        [STATION_FIELDS.IS_USING_MIN_RATE]: true
      }
    }
    else if (key === STATION_FIELDS.TOTAL_PAY) {
      data = {
        ...data,
        [STATION_FIELDS.IS_USING_MIN_RATE]: false
      }
    }

    const stationId = station.station_id
    return updateStation(stationId, data)
  })

  const isActive = station.is_active

  return (
    <StyledCard
      title={<p style={{ fontWeight: 'bold' }}>{station.station_name}</p>}
      extra={<Switch checked={station.is_active} onChange={(check) => handleUpdateStation('is_active', check)} />}
    >
      {isActive && (
        <>
          <Row style={{ marginBottom: '10px' }}>
            <Col xs={12} sm={12}>
              <Radio.Group onChange={(e) => handleOptionChange(e.target.value)} value={selectedOption} style={{ display: 'flex', flexDirection: 'column' }}>
                <Radio value={STATION_FIELDS.MIN_RATE}>Min Rate ($)</Radio>
                <Radio value={STATION_FIELDS.TOTAL_PAY}>Total Pay ($)</Radio>
              </Radio.Group>
            </Col>
            <Col xs={12} sm={12}>
              <p style={{marginTop: '25px'}}>Arrival Time</p>
            </Col>
          </Row>
          <Row>
            {selectedOption === STATION_FIELDS.MIN_RATE && (
                <Col xs={12} sm={12}>
                  <StyledInputNumber
                    prefix='$'
                    suffix='/ hr'
                    placeholder='Min Rate'
                    style={{ maxWidth: '150px' }}
                    onChange={(value) => handleUpdateStation(STATION_FIELDS.MIN_RATE, value)}
                    defaultValue={station.min_rate}
                  />
                </Col>
            )}
            {selectedOption === STATION_FIELDS.TOTAL_PAY && (
              <Col xs={12} sm={12}>
                <StyledInputNumber
                  prefix='$'
                  placeholder='Total Pay'
                  style={{ maxWidth: '150px' }}
                  onChange={(value) => handleUpdateStation(STATION_FIELDS.TOTAL_PAY, value)}
                  defaultValue={station.total_pay}
                />
              </Col>
            )}
            <Col xs={12} sm={12}>
              <StyledInputNumber
                suffix='mins'
                style={{ maxWidth: '150px' }}
                onChange={(value) => handleUpdateStation('arrival_time', value)}
                defaultValue={parseInt(station.arrival_time)}
              />
            </Col>
          </Row>
        </>
      )}
    </StyledCard>
  )
}

export default StationCard
