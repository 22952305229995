import React from 'react'
import { Col, Divider, Row } from 'antd'
import { connect } from 'react-redux'
import getOr from 'lodash/fp/getOr'
import { styled } from 'styled-components'

import { getAuthUser } from '../data/auth/selectors'
import Message from '../ui/message'

const ContentWrap = styled.div`\
  ${props => props.disabled && `
    button,
    input,
    [class^='ant-input'], [class^='ant-checkbox'], [class^='ant-select'], [class^='ant-switch'], [class^='ant-radio'] {
      pointer-events: none !important;
    }
    `}
`

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state)
  }
}

const DisabledWrapper = (props) => {
  const { children, user } = props

  const isRunning = getOr(false, 'is_running')(user)

  return (
    <div>
      {isRunning && (
        <Row>
          <Col xs={24} md={16}>
            <Message
              title="You cannot update settings while searching."
              warning
            />
            <Divider />
          </Col>
        </Row>
      )}
      <ContentWrap disabled={isRunning}>{children}</ContentWrap>
    </div>
  )
}

export default connect(mapStateToProps, null)(DisabledWrapper)
